import { useEffect, useRef } from "react";
import { sendClientSideSplitTrackEvent } from "@leafly-com/split-next";

import isMobileDevice from "utils/isMobileDevice";

import { getSplitKey } from "./getSplitKey";

const useTrackDispensaryPageViewSplitEvent = (properties?: {
  page: string;
  tier: string;
}) => {
  const pageViewTracked = useRef(false);

  useEffect(() => {
    if (!pageViewTracked.current && properties) {
      const splitKey = getSplitKey();

      sendClientSideSplitTrackEvent(
        {
          eventType: "guardrail_dispensary_pageView",
          properties: {
            isMobile: isMobileDevice() ? "true" : "false",
            ...properties,
          },
        },
        splitKey,
      );

      pageViewTracked.current = true;
    }
  }, [properties]);
};

export default useTrackDispensaryPageViewSplitEvent;
