import { stringify } from "qs";

export const getDispensaryUrl = ({
  countryCode,
  slug,
  tab,
  query,
}: {
  countryCode: string;
  slug: string;
  tab?: string;
  query?: { [key: string]: string | string[] };
}) => {
  const queryString = query && stringify(query, { arrayFormat: "brackets" });

  return `/${
    countryCode === "CA" ? "cannabis-store" : "dispensary-info"
  }/${slug}${tab ? `/${tab}` : ""}${queryString ? `?${queryString}` : ""}`;
};

export const dispensaryHref = ({
  additionalPath = "",
  countryCode,
  retailType,
  slug,
}: {
  additionalPath?: string;
  countryCode: string;
  retailType?: string;
  slug?: string;
}) => {
  if (countryCode === "CA") {
    return `/cannabis-store/${slug}${additionalPath}`;
  }
  if (retailType === "clinic") {
    return `/doctors/${slug}${additionalPath}`;
  }
  if (retailType === "cbd-store") {
    return `/cbd-store/${slug}${additionalPath}`;
  }
  return `/dispensary-info/${slug}${additionalPath}`;
};
