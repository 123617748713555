import { Dispatch, useCallback, useEffect, useReducer, useRef } from "react";

// Ensures that a reducer isn't called when the useEffect has already been cleaned up//cancelled.
const usePromiseReducer = <State>(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: fix me please, do not replicate
  reducer: any,
  initialState: State,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: fix me please, do not replicate
  initializer?: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: fix me please, do not replicate
): [State, Dispatch<any>] => {
  const [state, dispatch] = useReducer(reducer, initialState, initializer);

  const isValid = useRef(true);

  const validDispatch = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: fix me please, do not replicate
    (args: any) => {
      if (isValid.current) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: fix me please, do not replicate
        (dispatch as any)(args);
      }
    },
    [dispatch, isValid],
  );

  useEffect(() => {
    isValid.current = true;
    return () => {
      isValid.current = false;
    };
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: fix me please, do not replicate
  return [state as State, validDispatch as Dispatch<any>];
};

export default usePromiseReducer;
