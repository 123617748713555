import { trackEvent } from "@leafly-com/web-utils";

import { GetMenuItemsAvailableFilters } from "api/requests/getMenuItems";
import { SpecialFilters, StrainFilters } from "custom-types/AvailableFilters";
import {
  clearFinderStrainFilterQueryParams,
  clearQueryParams,
  setQueryParams,
} from "utils/nextRouterUtils";

export const SUB_FILTER_TYPES = {
  checkbox: "checkbox",
  radio: "radio",
};

export const RADIO_FILTERS = ["strain_name", "product_type"];

// filter pairs that cannot both be displayed at the same time
export const FILTER_TOGGLE_PAIRS = {
  delivery: "pickup",
  medical: "recreational",
  pickup: "delivery",
  recreational: "medical",
};

const sortFilters = (filters: string[]) =>
  filters.sort((a, b) =>
    a.localeCompare(b, undefined, { sensitivity: "base" }),
  );

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: fix me please, do not replicate
export const flattenFilters = (filters: any) =>
  filters.flatMap(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: fix me please, do not replicate
    (filter: { nested: any[] }) =>
      filter?.nested?.map((subFilter) => subFilter) || filter,
  );

export const nestedFilterValuesByName = (
  filters: GetMenuItemsAvailableFilters,
  name: string,
) =>
  filters
    ?.find((option) => option.name === name)
    ?.values?.map((value) => value.value);

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: fix me please, do not replicate
export const createFiltersObject = (availableFilters: any) =>
  flattenFilters(availableFilters).reduce(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: fix me please, do not replicate
    (acc: any, curr: { param: any; display: any }) => ({
      ...acc,
      [curr?.param]: curr?.display,
    }),
    {},
  );

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: fix me please, do not replicate
export const isFilterAvailable = (availableFilters: any, value: any) =>
  flattenFilters(availableFilters).some(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: fix me please, do not replicate
    (f: { type: any; param: any }) => f.type === value || f.param === value,
  );

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: fix me please, do not replicate
export const hasAvailableFilters = (filters: string | any[]) =>
  filters?.length > 0;

export const activeSubFilter = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: fix me please, do not replicate
  subFilters: any[],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: fix me please, do not replicate
  activeFilters: string | any[],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: fix me please, do not replicate
) => subFilters?.find((f: { param: any }) => activeFilters.includes(f.param));

export const getCurrentStrainFilter = (
  filterName: string | null,
  strainFilter: string | null,
) => (filterName === strainFilter ? null : filterName);

export const replaceFilterValue = (
  currentFilter: string,
  newFilter: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: fix me please, do not replicate
  currentFilters: any[],
) => {
  const i = currentFilters.indexOf(currentFilter);
  if (i >= 0) {
    currentFilters[i] = newFilter;
  }
  return currentFilters;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: fix me please, do not replicate
export const getSubFilters = (availableFilters: any[], param: any) =>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: fix me please, do not replicate
  availableFilters.reduce((acc: any, c: { param: any; nested: any }) => {
    if (c.param === param) {
      acc = c.nested;
    }
    return acc;
  }, []);

const _getCurrentFilters = (
  activeFilters: string[],
  filterName: string,
  label: string,
) => {
  if (activeFilters?.indexOf(filterName) >= 0) {
    return activeFilters.filter((f) => f !== filterName);
  }

  trackEvent("finder filter", "click", label.toLowerCase());
  return sortFilters([...activeFilters, filterName]);
};

export const toggleStrainFinderFilter = ({
  strainFilters,
  filter: { key, value },
}: {
  strainFilters: StrainFilters;
  filter: {
    key: keyof StrainFilters;
    value: string;
  };
}) => {
  if (strainFilters[key] === value) {
    clearQueryParams([key], true);
  } else {
    setQueryParams({ [key]: value }, true, ["page"]);
  }
};

export const removeAllStrainFilters = (
  strainFilters: StrainFilters,
  currentActiveFilters: string[],
) => {
  let newActiveFilters = currentActiveFilters;
  Object.values(strainFilters).forEach((strainFilter) => {
    if (strainFilter) {
      newActiveFilters = _getCurrentFilters(
        newActiveFilters,
        strainFilter,
        strainFilter,
      );
    }
  });
  clearFinderStrainFilterQueryParams();
  return newActiveFilters;
};

const ejectPreviousFilterValue = ({
  currentFilters,
  newFilterValue,
  previousFilterValue,
}: {
  currentFilters: string[];
  newFilterValue: string;
  previousFilterValue?: string | null;
}) => {
  if (previousFilterValue === newFilterValue || !previousFilterValue) {
    return currentFilters;
  }
  return currentFilters.filter((f) => f !== previousFilterValue);
};

/**
 * Defines the procedure when an incoming StrainFilter is set.
 * Manages StrainFinderFilter Query Params and Track Events.
 * @param strainFilters Existing StrainFilters state.
 * @param activeFilters Existing ActiveFilters state.
 * @param filter Defines the incoming Strain Filter key and value.
 * @returns Updated list of Active Filters.
 */
export const toggleStrainFilter = ({
  strainFilters,
  activeFilters,
  filter,
}: {
  strainFilters: StrainFilters;
  activeFilters: string[];
  filter: {
    key: keyof StrainFilters;
    value: string;
  };
}) => {
  toggleStrainFinderFilter({
    filter,
    strainFilters,
  });
  const currentFilters = _getCurrentFilters(
    activeFilters,
    filter.value,
    filter.value,
  );
  return ejectPreviousFilterValue({
    currentFilters,
    newFilterValue: filter.value,
    previousFilterValue: strainFilters[filter.key],
  });
};

export const getDispensaryFilters = (
  activeFilters: string[],
  specialFilters: SpecialFilters,
) => {
  return activeFilters.filter(
    (f) =>
      ![
        specialFilters.strain,
        specialFilters.product_type,
        specialFilters.organization,
      ].includes(f),
  );
};

const _processActiveFilters = (filters: string[], filter: string) =>
  filters.includes(filter)
    ? filters.filter((f) => f !== filter)
    : [...filters, filter];

export const updateFilters = ({
  filterName,
  activeFilters,
  specialFilters,
  label,
}: {
  filterName: string;
  activeFilters: string[];
  specialFilters: SpecialFilters;
  label: string;
}) => {
  const _updateDispensaryFilterQueryParams = (dispensaryFilters: string[]) => {
    if (dispensaryFilters.length === 0) {
      clearQueryParams(["filter", "page"], true);
    } else {
      setQueryParams({ filter: sortFilters(dispensaryFilters) }, true, [
        "page",
      ]);
    }
  };

  _updateDispensaryFilterQueryParams(
    getDispensaryFilters(
      _processActiveFilters(activeFilters, filterName),
      specialFilters,
    ),
  );

  return _getCurrentFilters(activeFilters, filterName, label);
};
