import omit from "lodash/omit";
import Router, { Router as RouterType } from "next/router";

export const handleNewShallowRoute = (
  route: string,
  pathname: string,
  query = {},
  shouldReplace = false,
): Promise<boolean> => {
  const sortedQuery = sortParameters(query);
  return Router[shouldReplace ? "replace" : "push"](
    { pathname: route, query: sortedQuery },
    { pathname, query: sortedQuery },
    { shallow: true },
  );
};

const sanitizePath = (path: string) => path.match(/([^?]+)/)?.[0] ?? path;

export type ParsedUrlQueryParam = string | string[] | undefined;

export type QueryParam = ParsedUrlQueryParam | number | boolean;

export type QueryParams = {
  lat?: string | number;
  lng?: string | number;
  lon?: string | number;
  zoom?: string | number;
  radius?: string;
  organization_id?: string | number;
  filter?: string[] | string;
  sort?: string;
  strain?: string;
  product_type?: string;
  page?: string | number;
  utm_source?: string;
  utm_medium?: string;
  utm_campaign?: string;
  [key: string]: QueryParam;
};

type ProcessFinderQueryParams<T = QueryParams> = (query: T) => T;

export const processFinderQueryParams: ProcessFinderQueryParams = (query) => {
  const {
    lat,
    lng,
    lon: longitude = null,
    zoom,
    radius,
    organization_id,
    filter,
    sort,
    strain,
    product_type,
    page,
    utm_source,
    utm_medium,
    utm_campaign,
  } = query;

  const lon = lng ?? longitude;

  // We want to use lon instead of lng for consistency across the app, but will support both here for legacy urls
  const mapParams = lat && lon && zoom ? { lat, lon, zoom } : {};

  return {
    ...(filter && { filter }),
    ...mapParams,
    ...(organization_id && { organization_id }),
    ...(page && { page }),
    ...(product_type && { product_type }),
    ...(radius && { radius }),
    ...(sort && { sort }),
    ...(strain && { strain }),
    ...(utm_source && { utm_source }),
    ...(utm_medium && { utm_medium }),
    ...(utm_campaign && { utm_campaign }),
  };
};

export type Exclusion =
  | "product_type"
  | "strain"
  | "filter"
  | "sort"
  | "page"
  | "lat"
  | "lng"
  | "lon"
  | "zoom"
  | "radius"
  | "organization_id";

export type QueryParamExclusions = Exclusion[];

type SetQueryParams<T = QueryParams> = (
  query?: T,
  shouldReplace?: boolean,
  exclusions?: QueryParamExclusions,
) => void;

export const setQueryParams: SetQueryParams = (
  query = {},
  shouldReplace = false,
  exclusions = [],
) => {
  const { asPath, route, query: routerQuery } = Router;

  const modifiedQuery = excludeQueryParam(
    { ...routerQuery, ...query },
    exclusions,
  );

  return handleNewShallowRoute(
    route,
    sanitizePath(asPath),
    processFinderQueryParams(modifiedQuery),
    shouldReplace,
  );
};

export const setPageQueryParam = (
  page: number,
  shouldReplace?: boolean,
  exclusions?: QueryParamExclusions,
) => setQueryParams({ page }, shouldReplace, exclusions);

export const clearQueryParams = (
  exclusions: QueryParamExclusions = [],
  shouldReplace = false,
) => {
  const { route, asPath, query } = Router;

  const modifiedQuery = excludeQueryParam(query, exclusions);

  return handleNewShallowRoute(
    route,
    sanitizePath(asPath),
    processFinderQueryParams(modifiedQuery),
    shouldReplace,
  );
};

export type ProcessQueryParamExclusions<T> = (
  query: T,
  exclusions: QueryParamExclusions,
) => T;

const excludeQueryParam: ProcessQueryParamExclusions<QueryParams> = (
  query,
  exclusions,
) =>
  exclusions.length
    ? Object.keys(query).reduce((acc, curr) => {
        if (!exclusions.includes(curr as Exclusion)) {
          return { ...acc, [curr]: query[curr] };
        }
        return acc;
      }, {})
    : query;

export const processQueryParamExclusions: ProcessQueryParamExclusions<
  QueryParams
> = (query, exclusions) =>
  Object.keys(query).reduce((acc, curr) => {
    if (exclusions.includes(curr as Exclusion)) {
      return { ...acc, [curr]: query[curr] };
    }
    return acc;
  }, {});

export const clearFinderStrainFilterQueryParams = (
  exclusions: QueryParamExclusions = [],
  queryProcessor = processFinderQueryParams,
) => {
  const { route, asPath, query } = Router;

  const routerQuery = omit(query, ["strain", "product_type"]);

  const _query = processQueryParamExclusions(routerQuery, exclusions);

  return handleNewShallowRoute(
    route,
    sanitizePath(asPath),
    {
      ...queryProcessor(routerQuery),
      ...queryProcessor(_query),
    },
    true,
  );
};

export const clearAllFinderFilterQueryParams = (
  exclusions: QueryParamExclusions = [],
  queryProcessor = processFinderQueryParams,
) => {
  const { route, asPath, query: routerQuery } = Router;

  const query = processQueryParamExclusions(
    omit(routerQuery, [
      "filter",
      "medical",
      "recreational",
      "strain",
      "product_type",
    ]),
    exclusions,
  );

  return handleNewShallowRoute(
    route,
    sanitizePath(asPath),
    queryProcessor(query),
    true,
  );
};

export const clearAllQueryParams = (
  shouldReplace = false,
  exclusions: QueryParamExclusions = ["lat", "lng", "lon", "zoom"],
  queryProcessor = processFinderQueryParams,
) =>
  handleNewShallowRoute(
    Router.route,
    sanitizePath(Router.asPath),
    queryProcessor(processQueryParamExclusions(Router.query, exclusions)),
    shouldReplace,
  );

export const getPageUrl = (query = {}) => {
  const {
    asPath,
    query: { lat, lng, lon, zoom, ...queryRest },
  } = Router;
  const mapParams =
    lat && (lng || lon) && zoom ? { lat, lon: lon || lng, zoom } : {};

  const routerQuery = omit(queryRest, [
    "retailType",
    "slug",
    "city",
    "state",
    "info",
    "location",
    "page",
    "finder",
  ]);

  return {
    pathname: sanitizePath(asPath),
    query: {
      ...mapParams,
      ...routerQuery,
      ...query,
    },
  } as RouterType;
};

export function getFirstQueryParam<T>(
  routerParam: T | T[] | undefined,
): T | undefined;
export function getFirstQueryParam<T>(
  routerParam: T | T[] | undefined,
  defaultValue: T,
): T;
export function getFirstQueryParam<T>(
  routerParam: T | T[] | undefined,
  defaultValue?: T,
) {
  if (routerParam === undefined || routerParam === null) {
    return defaultValue;
  } else if (Array.isArray(routerParam)) {
    return routerParam[0];
  } else {
    return routerParam;
  }
}

export const sortParameters = (parameters: QueryParams) =>
  Object.keys(parameters)
    .sort()
    .reduce((obj, key) => {
      obj[key] = parameters[key];
      return obj;
    }, {} as QueryParams);
