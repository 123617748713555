// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: fix me please, do not replicate
export const normalizeLegacyParams = (params: any) => {
  const {
    brand,
    strain,
    strainName,
    query,
    thc_pips_min,
    thc_pips_max,
    cbd_pips_min,
    cbd_pips_max,
    price_min,
    price_max,
    "menu-page": menuPage,
    ...rest
  } = params;

  const normalizedParams = {
    brand_name: brand,
    cbd_pips: (cbd_pips_min || cbd_pips_max) && {
      max: cbd_pips_max,
      min: cbd_pips_min,
    },
    page: menuPage,
    price: (price_min || price_max) && {
      max: price_max,
      min: price_min,
    },
    q: query,
    strain_name: strain || strainName,
    thc_pips: (thc_pips_min || thc_pips_max) && {
      max: thc_pips_max,
      min: thc_pips_min,
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: fix me please, do not replicate
  } as any;

  Object.entries(normalizedParams).forEach(([key, value]) => {
    if (!value) {
      delete normalizedParams[key];
    }
  });

  return { ...normalizedParams, ...rest };
};
