import React from "react";

import { CATEGORY_NAMES } from "constants/dispensary";
import { Action, Category } from "constants/events";
import { useEventTracker } from "hooks/useEventTracker";

import Coin from "components/Coin";
import Placeholder, { Rect } from "components/Placeholder";

type Props = {
  className?: string;
  coins: string[];
  hrefLink?: string;
  hrefLinkQueryParam?: string;
  hrefProductCatQueryKey?: string;
  isLoading?: boolean;
  title?: string;
};

const CoinList: React.FC<Props> = ({
  className,
  coins,
  hrefLink = "/search/shop",
  hrefLinkQueryParam = "",
  hrefProductCatQueryKey = "product_category",
  isLoading,
  title,
}) => {
  const { publishEvent } = useEventTracker();

  const coinCategories = Object.keys(CATEGORY_NAMES).filter((category) =>
    coins.includes(category),
  );

  if (!isLoading && !coinCategories.length) {
    return <></>;
  }

  return (
    <div className={className}>
      {title && <h2 className="heading--m font-extrabold mb-md">{title}</h2>}
      <div className="overflow-x-auto">
        <ul data-testid="coin-list" className="flex gap-lg">
          {isLoading
            ? Array.from({ length: 6 }).map((_, i) => (
                <li
                  className="flex-shrink-0 mb-2 md:mb-none"
                  key={`category-coin-placeholder-${i}`}
                >
                  <Placeholder
                    className="w-20 h-20"
                    data-testid="category-coin-placeholder"
                  >
                    <Rect height="100%" width="100%" />
                  </Placeholder>
                </li>
              ))
            : coinCategories.map((category) => (
                <Coin
                  key={category}
                  href={`${hrefLink}?${hrefProductCatQueryKey}=${category}${hrefLinkQueryParam}`}
                  onClick={() => {
                    publishEvent({
                      action: Action.click,
                      category: Category.productCategoryCoinFilter,
                      label: CATEGORY_NAMES[category]
                        .toLowerCase()
                        .replace(/-/g, ""),
                    });
                  }}
                  title={CATEGORY_NAMES[category].toLowerCase()}
                  dataTestId={`${category}-products-category`}
                  iconFilePath={`coinList/${category.toLowerCase()}.svg`}
                />
              ))}
        </ul>
      </div>
    </div>
  );
};

export default CoinList;
